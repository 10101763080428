import { Dispatch } from 'redux'

import { Menu } from '@library/menu-service'

import * as trackingKeys from 'actions/trackingKeys'

import { actionTypes } from './actionTypes'

export function menuServiceV3DataReceived(response: Menu) {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: actionTypes.MENU_SERVICE_V3_DATA_RECEIVED,
      response,
      trackingData: {
        actionType: trackingKeys.receiveMenuServiceDataV3,
      },
    })
  }
}
