import actions from 'actions'
import now from 'performance-now'
import { Dispatch } from 'redux'

import { ThunkExperiments } from '@library/experimentation'
import {
  menuFetcher,
  menuFetcherV3,
  MenuRequestParams,
  MenuRequestParamsV3,
} from '@library/menu-service'

import { actionTypes } from 'actions/actionTypes'
import { getMenuCutoffUntil } from 'actions/basket'
import { boxSummaryDeliveryDaysLoad } from 'actions/boxSummary'
import { menuCutoffUntilReceive, menuLoadComplete } from 'actions/menu'
import { menuServiceDataReceived } from 'actions/menuService'
import { menuServiceV3DataReceived } from 'actions/menuV3'
import { getRequestHeaders } from 'routes/Menu/apis/_utils'
import { isChosenSlotAvailable } from 'routes/Menu/selectors/boxSummary'
import { getSignupRecipePreferences } from 'routes/Signup/signupSelectors'
import { getIsAdmin, getIsAuthenticated, getAccessToken, getAuthUserId } from 'selectors/auth'
import { getBasketDate, getNumPortions } from 'selectors/basket'
import { getSessionId } from 'selectors/cookies'
import { getMenuCutoffUntil as getMenuCutOffUntilSelector } from 'selectors/root'
import { getLandingDay } from 'utils/deliveries'

import { sendClientMetric } from '../apis/clientMetrics'
import { AddRecipeFn } from '../domains/basket'
import { GetState, Query } from './types'
import {
  selectCollection,
  getPreselectedCollectionName,
  setSlotFromIds,
  parseNumPortions,
} from './utils'

const chooseFirstDate = () => (dispatch: Dispatch<any>, getState: GetState) => {
  const { date } = getLandingDay(getState())

  return dispatch(actions.basketDateChange(date))
}

const handleQueryError = (error: any) => (dispatch: Dispatch<any>) => {
  dispatch(actions.error(actionTypes.ORDER_SAVE, error))
}

const beforeMenuLoad = (query: Query) => (dispatch: Dispatch<any>, getState: GetState) => {
  if (getState().basket.get('orderId')) {
    const shippingAddresses = getState().user.get('shippingAddresses')

    const addressToSelect =
      shippingAddresses.find((address: any) => address.get('shippingDefault') === true) ||
      shippingAddresses.first()

    dispatch(actions.basketReset(addressToSelect))
  }

  if (
    query.day_id ||
    query.slot_id ||
    getBasketDate(getState()) ||
    getState().basket.get('slotId')
  ) {
    dispatch(setSlotFromIds(query.slot_id, query.day_id))
  } else if (!getBasketDate(getState())) {
    chooseFirstDate()(dispatch, getState)
  }

  if (query.num_portions) {
    dispatch(actions.basketNumPortionChange(query.num_portions))
  }

  if (query.postcode && !getState().basket.get('postcode')) {
    dispatch(actions.basketPostcodeChangePure(query.postcode))
  }
}

function handleExpiredBasketDate({
  dispatch,
  getState,
}: {
  dispatch: Dispatch<any>
  getState: GetState
}) {
  if (!isChosenSlotAvailable(getState())) {
    const { date } = getLandingDay(getState(), {
      useBasketDate: false,
    })

    dispatch(actions.basketDateChange(date))
  }
}

const selectCollectionFromQuery =
  (query: Query) => (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState()
    const collectionName = getPreselectedCollectionName(state, query.collection)
    dispatch(selectCollection(collectionName))
  }

// eslint-disable-next-line import/no-default-export
export default function fetchData(
  { query, params }: { query: Query; params: any },
  force: boolean,
  background: any,
  { addRecipe }: { addRecipe: AddRecipeFn },
) {
  return async (dispatch: Dispatch<any>, getState: GetState, { experiments }: ThunkExperiments) => {
    const startTime = now()

    const isPending = getState().pending && getState().pending.get(actionTypes.MENU_FETCH_DATA)
    const isAdminQuery = !!(query && query['preview[auth_user_id]'])

    if (!isAdminQuery && isPending) {
      return
    }

    dispatch(actions.pending(actionTypes.MENU_FETCH_DATA, true))
    dispatch(actions.error(actionTypes.MENU_FETCH_DATA, false))

    if (params.orderId) {
      dispatch(actions.basketIdChange(params.orderId))
    } else {
      const isAuthenticated = getIsAuthenticated(getState())
      const isAdmin = getIsAdmin(getState())

      if (isAuthenticated && !isAdmin) {
        await dispatch(actions.userLoadOrders())
      }
    }

    try {
      let cutOffUntil = getMenuCutOffUntilSelector(getState())
      if (!cutOffUntil) {
        // This will fetch from /active endpoint
        cutOffUntil = await getMenuCutoffUntil(getState)
        dispatch(menuCutoffUntilReceive(cutOffUntil))
        await dispatch(boxSummaryDeliveryDaysLoad(undefined, cutOffUntil))
      }

      const overrideNumPortions = parseNumPortions(query.selected_box_size)
      // we need the delivery days to calculate the date to pass to the menu API based on the delivery date using the slots data
      if (params.orderId) {
        const { auth, user } = getState()
        if (auth.get('isAuthenticated') && !user.get('email') && !auth.get('isAdmin')) {
          await dispatch(actions.userLoadData())
        }
        await dispatch(actions.menuLoadOrderDetails(params.orderId, addRecipe, overrideNumPortions))
      } else {
        beforeMenuLoad(query)(dispatch, getState)
        handleExpiredBasketDate({ dispatch, getState })
      }

      const accessToken = getAccessToken(getState())
      const isAuthenticated = getIsAuthenticated(getState())
      const userId = getAuthUserId(getState())
      const recipePreferenceIds = getSignupRecipePreferences(getState()).join(',')
      const deliveryDate = getBasketDate(getState())

      const { variationKey } = experiments.decide('turnips_webclient_menu_service_v3_migration')

      if (variationKey === 't1') {
        const menuResponseV3 = await menuFetcherV3({
          deliveryDate,
          numPortions: overrideNumPortions || getNumPortions(getState()),
          sessionId: getSessionId(),
        } as MenuRequestParamsV3)

        dispatch(menuServiceV3DataReceived(menuResponseV3))
      }

      const menuResponse = await menuFetcher({
        accessToken,
        isAuthenticated,
        userId,
        deliveryDate,
        recipePreferenceIds,
        headers: getRequestHeaders(userId),
        ...query,
      } as MenuRequestParams)

      if (isAdminQuery) {
        dispatch(actions.basketDateChange(menuResponse.data[0].attributes.ends_at.split('T')[0]))
      }

      dispatch(menuServiceDataReceived(menuResponse))

      if (query.error) {
        handleQueryError(query.error)(dispatch)
      }

      dispatch(actions.menuLoadMenu())
      dispatch(actions.pending(actionTypes.MENU_FETCH_DATA, false))

      if (params.orderId) {
        sendClientMetric('menu-edit-initiated', 1, 'Count')
      }

      selectCollectionFromQuery(query)(dispatch, getState)

      const timeTaken = Math.round(now() - startTime)
      dispatch(menuLoadComplete(timeTaken, true))
    } catch (e) {
      dispatch(actions.pending(actionTypes.MENU_FETCH_DATA, false))
      dispatch(actions.error(actionTypes.MENU_FETCH_DATA, true))
      throw e
    }
  }
}
