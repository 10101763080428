import { Action } from 'redux'

import { Menu } from '@library/menu-service'

import { actionTypes } from 'actions/actionTypes'

export const menuInitialState = {
  menuV3: {},
  menuLoadingErrorMessage: '',
}

export type MenuAction = Action<string> & { response: Partial<Menu> }

export const menuV3Reducers = {
  menuV3: (state = menuInitialState, action: MenuAction) => {
    switch (action.type) {
      case actionTypes.MENU_SERVICE_V3_DATA_RECEIVED: {
        return {
          ...state,
          menuV3: action.response,
          menuLoadingErrorMessage: '',
        }
      }

      default:
        return state
    }
  },
}
